import { observable } from 'mobx';

export class HistoryWidgetStore {
	@observable records = [];
	@observable isLoading = true;
	@observable filter = null;
	@observable page = 1;
	@observable loadingMore = false;
	recordsPerPage = 15;

	constructor(model, filter) {
		this.model = model;
		this.filter = filter;
		this.init();
	}

	init = async () => {
		await this.fetchRecords();
	};

	fetchRecords = async () => {
		if (this.model) {
			const skip = (this.page - 1) * this.recordsPerPage;
			const _records = await this.model.find({ limit: this.recordsPerPage, skip, ...this.filter });
			this.records = this.page === 1 ? _records : [...this.records, ..._records];
		}
		this.isLoading = false;
	};

	reload = () => {
		this.page = 1;
		this.fetchRecords();
	};

	updateFilter = (filter) => {
		this.filter = filter;
	};

	onScroll = async (e) => {
		if (this.loadingMore || this.records.length >= this.records.totalRecords) return;
		const bottomScroll = e.target.scrollHeight - e.target.scrollTop - e.target.offsetHeight;
		if (bottomScroll < 50) {
			this.loadingMore = true;
			this.page = this.page + 1;
			await this.fetchRecords();
			this.loadingMore = false;
		}
	};
}
