import React from 'react';
import { observer } from 'mobx-react';
import t from 'i18n';
import { Log } from 'components';
import { renderStatus } from 'client/tools';
import store from 'client/store';

const manualFields = ['description', 'username', 'name', 'email', 'phone'];

@observer
export class DialogLog extends React.Component {
	constructor(props) {
		super(props);
	}

	showDelta = async (e, field, index) => {
		e.preventDefault();
		if (this.props.onShowPopup) this.props.onShowPopup(field, this.props.log, index);
	};

	render() {
		const { log, index } = this.props;
		const isUpdated = (field) => log.updated && log.updated.find((_field) => _field === field);

		const updatedFields = [];
		manualFields.forEach((field) => isUpdated(field) && updatedFields.push(field));

		let actions = [];

		if (log.updated) {
			if (updatedFields.length > 0) {
				actions.push({
					title: t('history.updated') + ':',
					subject: updatedFields.map((field, i) => {
						const onClick = (e) => this.showDelta(e, field, index);
						return (
							<React.Fragment key={i}>
								<a href='#' onClick={onClick}>
									{t(`dialog.${field}`).toLowerCase()}
								</a>
								{updatedFields.length > 0 && i < updatedFields.length - 1 ? ', ' : ''}
							</React.Fragment>
						);
					}),
				});
			}

			if (isUpdated('isClosed')) {
				if (log.isClosed) {
					actions.push({
						title: t('history.dialog.close'),
					});
				} else {
					actions.push({
						title: t('history.dialog.open'),
					});
				}
			}

			if (isUpdated('isSpam')) {
				if (log.isSpam) {
					actions.push({
						title: t('history.dialog.spamOn'),
					});
				} else {
					actions.push({
						title: t('history.dialog.spamOff'),
					});
				}
			}

			if (isUpdated('statusId')) {
				actions.push({
					title: t('history.dialog.status'),
					subject: renderStatus(log.status),
				});
			}

			if (isUpdated('employeeId')) {
				actions.push({
					title: t('history.dialog.employee'),
				});
			}
		}

		return <Log instanceLog={log} actions={actions} className='task-log' roles={this.props.roles} />;
	}
}
