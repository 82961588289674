import React from 'react';
import Root from './Root';
import t from 'i18n';
import { faGear, faHandshake } from '@fortawesome/free-solid-svg-icons';
import { CONTRAGENTS_PATH, CONTRAGENTS_SETTINGS_PATH } from './constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default {
	title: t('contragent.plural'),
	titleShort: t('contragent.titleShort'),
	code: 'contragents',
	enabled: (store) => store.model.ContragentType && store.model.ContragentType.INFO.WRITE,
	component: Root,
	menu: () => [
		{
			type: 'item',
			label: t('contragents.plural'),
			path: CONTRAGENTS_PATH,
			reactIcon: <FontAwesomeIcon icon={faHandshake} />,
			enabled: (store) => store.model.Contragent && store.model.Contragent.INFO.WRITE,
		},
		{
			type: 'item',
			label: t('settings'),
			path: CONTRAGENTS_SETTINGS_PATH,
			reactIcon: <FontAwesomeIcon icon={faGear} />,
			enabled: (store) => store.model.ContragentType && store.model.ContragentType.INFO.WRITE,
		},
	],
};
