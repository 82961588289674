import { PROJECTS_PATH, MANAGEMENT_TASKS_PATH, DASHBOARD_PATH, MANAGEMENT_SETTINGS_PATH } from './constants';
import Root from './Root';
import t from 'i18n';
import { faCalendarDays, faDesktop, faGear, faThumbTack } from '@fortawesome/free-solid-svg-icons';

/**
 * Конфигурация модуля.
 */

export default {
	/** Заголовок модуля */
	title: t('management'),

	/** Короткий заголовок модуля */
	titleShort: t('management'),

	/** Код модуля на латинице, обязателен */
	code: 'management',

	/** Опциональная дополнительная функция проверки доступности модуля */
	enabled: (store) => store.model.Task && store.model.Task.INFO.READ,

	/** Главный компонент с роутами и компонентами модуля */
	component: Root,

	/** Необязательный компонент для общего дашбоарда, см. исходник */

	// dashboardCard: {
	// 	component: ManagementCard,
	// 	icon: faCalendarDays,
	// 	label: t('dashboard.plural'),
	// 	path: DASHBOARD_PATH,
	// },

	/** Меню модуля, может быть массивом или функцией (в аргументе передастся глобальный стор) */
	menu: () => [
		{
			type: 'item',
			label: t('dashBoard'),
			path: DASHBOARD_PATH,
			icon: faDesktop,
			// enabled: (store) => store.model.Project && store.model.Project.INFO.READ,
		},
		{
			type: 'item',
			label: t('project.plural'),
			path: PROJECTS_PATH,
			icon: faCalendarDays,
			enabled: (store) => store.model.Project && store.model.Project.INFO.READ,
		},
		{
			type: 'item',
			label: t('task.plural'),
			path: MANAGEMENT_TASKS_PATH,
			icon: faThumbTack,
			enabled: (store) => store.model.Task && store.model.Task.INFO.READ,
			badgeFunc: async (store) =>
				await store.model.Task.count({
					userId: store.model.user.id,
					or: [{ closed: false }, { closed: null }],
				}),
			badgeEvent: 'Task.userId',
		},
		{
			type: 'item',
			label: t('settings'),
			path: MANAGEMENT_SETTINGS_PATH,
			icon: faGear,
			enabled: (store) => store.model.Task && store.model.Task.INFO.WRITE && store.model.ProjectList && store.model.ProjectList.INFO.WRITE,
		},
	],
};

