import React from 'react';
import { observer, Provider } from 'mobx-react';
import { SingleDialogStore } from 'client/modules/messages/dialogs/single/singleDialogStore';
import { Loader, Tab, Tabs } from '@smartplatform/ui';
import MainSection from 'client/modules/messages/dialogs/single/main/MainSection';
import Sidebar from 'client/modules/messages/dialogs/single/Sidebar';
import { DialogLog } from 'client/modules/messages/dialogs/single/history';
import t from 'i18n';
import store from 'client/store';
import { IconTitle, Section, triggerErrorNotification } from 'components';
import { MessagesWidget } from '../components/messages-widget';
import { HistoryWidget } from 'components/history-widget';
import { DIALOG_TABS } from 'client/modules/messages/dialogs/constants';
import classNames from 'classnames';
import HistoryDeltaPopup from 'components/history-delta-popup/HistoryDeltaPopup';
import './style.scss';
import { ICONS } from 'client/modules/messages/constants';

const FUNCTION_NAMES = {
	VK: 'VKSendMessage',
	TLG: 'telegramSendMessage',
	EMAIL: 'emailSendMessage',
};

@observer
export class SingleDialog extends React.Component {
	constructor(props) {
		super(props);
		const dialogId = props.match.params?.id;
		this.store = new SingleDialogStore(dialogId);
	}

	onSubmitMessage = async (message) => {
		const { dialog } = this.store;
		try {
			if (FUNCTION_NAMES[dialog?.source?.code])
				await store.model.DialogSource[FUNCTION_NAMES[dialog.source.code]]({
					externalId: dialog.externalId,
					messageId: message.id,
					email: dialog.email,
				});
		} catch (error) {
			console.error(error);
			if (error?.message) triggerErrorNotification(t('postMessage.errorSend'));
		}
	};
	renderDialogLog = (record, index) => {
		return <DialogLog key={record.id} log={record} index={index} onShowPopup={this.store.showHistoryPopup} />;
	};

	render() {
		const { isLoading, dialog, activeTab, onTabChange, historyOrder, onChangeHistoryOrder, historyPopup } = this.store;

		if (isLoading) return <Loader size={20} />;
		if (!dialog) return null;

		const disabledSidebar = dialog.isSpam;

		const NoOwnerAvatar = ICONS[dialog.source.code];

		const tabs = {
			[DIALOG_TABS.MESSAGES]: (
				<MessagesWidget relation='messages' record={dialog} onSubmit={this.onSubmitMessage} noOwnerAvatar={<NoOwnerAvatar />} />
			),
			[DIALOG_TABS.LOG]: (
				<HistoryWidget
					model={store.model.DialogLog}
					filter={{ where: { and: [{ dialogId: dialog.id }, { updated: { neq: null } }] }, order: historyOrder }}
					renderItem={this.renderDialogLog}
				/>
			),
			[DIALOG_TABS.CONTACT]: <MainSection />,
		};

		const tabContent = tabs[activeTab] ? tabs[activeTab] : tabs[DIALOG_TABS.MESSAGES];

		const headerSection = dialog.name || dialog.email || dialog.phone || dialog.username;

		return (
			<Provider store={this.store}>
				<div className='wrapper-single-application'>
					<div className='left-application'>
						<Section
							className='history-dialog'
							title={
								<div className='title'>
									<span>{headerSection}</span>
									{activeTab === DIALOG_TABS.LOG && (
										<SortIcon trigger={historyOrder === 'id asc'} onClick={onChangeHistoryOrder} />
									)}
								</div>
							}
						>
							<Tabs type='manual'>
								<Tab
									title={<IconTitle text={t('history.dialog.messages')} />}
									onClick={() => onTabChange(DIALOG_TABS.MESSAGES)}
								/>
								<Tab title={t('dialog.contact')} onClick={() => onTabChange(DIALOG_TABS.CONTACT)} />
								<Tab title={<IconTitle text={t('dialog.log')} />} onClick={() => onTabChange(DIALOG_TABS.LOG)} />
							</Tabs>
							{tabContent}
							{historyPopup && <HistoryDeltaPopup {...historyPopup} />}
						</Section>
					</div>
					{!disabledSidebar && (
						<div className='right-application'>
							<Sidebar />
						</div>
					)}
				</div>
			</Provider>
		);
	}
}

const SortIcon = ({ trigger, ...restProps }) => (
	<div className={classNames('icon-sort', { sorted: trigger })} {...restProps}>
		<div className='top' />
		<div className='medium' />
		<div className='bot' />
	</div>
);
