import Root from './Root';
import React from 'react';
import {
	MANUFACTURE_PRODUCTION_WORKSHOPS_PATH,
	MANUFACTURE_EQUIPMENTS_PATH,
	MANUFACTURE_PRODUCTION_PROCESSES_PATH,
	MANUFACTURE_MATERIALS_PATH,
	MANUFACTURE_TECH_MAP_PATH,
	MANUFACTURE_SETTINGS_PATH,
	MANUFACTURE_PRODUCTION_ORDERS_PATH,
	MANUFACTURE_REPORTS_PATH,
	MANUFACTURE_DASHBOARD_PATH,
} from 'client/modules/constants';
import t from 'i18n';

import {
	faCubes,
	faScrewdriverWrench,
	faGear,
	faGears,
	faDroplet,
	faRandom,
	faClipboard,
	faDesktop,
} from '@fortawesome/free-solid-svg-icons';
import ProductionIcon from 'client/img/sidebar/production.svg';

export default {
	title: t('productionOrder.menu'),
	titleShort: t('productionOrder.titleShort'),
	code: 'manufacture',
	enabled: (store) => store.model.ProductionOrder && store.model.ProductionOrder.INFO.READ,
	component: Root,
	menu: () => [
		{
			type: 'item',
			label: t('analytics.title'),
			path: MANUFACTURE_DASHBOARD_PATH,
			icon: faDesktop,
		},
		{
			type: 'item',
			label: t('productionOrder.plural'),
			path: MANUFACTURE_PRODUCTION_ORDERS_PATH,
			reactIcon: <ProductionIcon />,
			enabled: ({ model }) => model.ProductionOrder?.INFO.READ,
		},
		{
			type: 'item',
			label: t('productionOrder.workshops'),
			path: MANUFACTURE_PRODUCTION_WORKSHOPS_PATH,
			icon: faCubes,
			enabled: ({ model }) => model.ProductionWorkshop?.INFO.READ,
		},
		{
			type: 'item',
			label: t('productionOrder.equipments'),
			path: MANUFACTURE_EQUIPMENTS_PATH,
			icon: faScrewdriverWrench,
			enabled: ({ model }) => model.ProcessEquipment?.INFO.READ,
		},
		{
			type: 'item',
			path: MANUFACTURE_PRODUCTION_PROCESSES_PATH,
			label: t('productionOrder.process'),
			icon: faGears,
			enabled: ({ model }) => model.ProductionProcess?.INFO.READ,
		},
		{
			type: 'item',
			path: `${MANUFACTURE_MATERIALS_PATH}`,
			label: t('productionOrder.materials'),
			icon: faDroplet,
			enabled: ({ model }) => model.Material?.INFO.READ,
		},
		{
			type: 'item',
			path: MANUFACTURE_TECH_MAP_PATH,
			label: t('productionOrder.techMap'),
			icon: faRandom,
			enabled: ({ model }) => model.ProductionWorkshop?.INFO.READ,
		},
		{
			type: 'item',
			label: t('reports.plural'),
			path: MANUFACTURE_REPORTS_PATH,
			icon: faClipboard,
		},
		{
			type: 'item',
			path: MANUFACTURE_SETTINGS_PATH,
			label: t('settings'),
			icon: faGear,
			enabled: ({ model }) => model.ProductionOrder && model.ProductionOrder.INFO.WRITE,
		},
	],
};
