import Cleave from 'cleave.js/react';
import React from 'react';
import store from 'client/store';
import './currencyInput.scss';
import classNames from 'classnames';
export const CurrencyInput = (props) => {
	const { currency, value, ...restProps } = props;
	const locale = store.local.language || 'ru';

	// название валюты из локали
	const currencySymbol = currency
		? (0)
				.toLocaleString(locale, {
					style: 'currency',
					currency: currency,
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				})
				.replace(/\d/g, '')
				.trim() + ' '
		: null;

	// десятичный сепаратор из локали
	const { decimalSeparator, delimiter } = (() => {
		// defaults
		let res = { decimalSeparator: '.', delimiter: '' };

		// convert a number formatted according to locale
		const str = (1234.56).toLocaleString(locale);

		// if the resulting number does not contain previous number
		// (i.e. in some Arabic formats), return defaults
		if (!str.match('1')) return res;

		// get decimal and thousand separators
		res.decimalSeparator = str.replace(/.*4(.*)5.*/, '$1');
		res.delimiter = str.replace(/.*1(.*)2.*/, '$1');

		// return results
		return res;
	})();

	const _onChange = (e) => {
		let value = e.target.value;

		if (!value) {
			value = null;
		} else {
			// возвращаем точку в onChange, если десятичный разделитель запятая
			if (decimalSeparator === ',') {
				value = value.replace(',', '.');
			}

			// разделитель для тысяч тож надо убрать
			const delimiterReg = new RegExp(`${delimiter}`, 'g');
			value = parseFloat(value.replace(delimiterReg, ''));
		}
		props.onChange?.(value, e);
	};
	const symbolPosition = store.local.language === 'ru' ? 'right' : 'left';

	let _value = value === undefined || value === null ? '' : String(value);
	// форматируем строку в нужной Cleave (относительно разделителя запятой для десятичных)
	if (decimalSeparator === ',') {
		_value = _value.replace('.', ',');
	}
	const symbolEl = <div className='symbol'>{currencySymbol}</div>;
	const className = classNames('currency-input', 'ui-input');
	return (
		<div className={className}>
			{symbolPosition === 'left' && symbolEl}
			<Cleave
				options={{
					numeral: true,
					numeralPositiveOnly: true,
					numeralDecimalScale: 2,
					numeralDecimalMark: decimalSeparator,
					delimiter: delimiter,
				}}
				{...restProps}
				onChange={_onChange}
				value={_value}
				className={'currency-input'}
				style={{ textAlign: symbolPosition }}
			/>
			{symbolPosition === 'right' && symbolEl}
		</div>
	);
};

