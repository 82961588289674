import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Loader } from '@smartplatform/ui';
import { Button } from '../../buttons';
import { ErrorMessage, FieldPassword } from '../components';
import { Logo } from '../components/logo/Logo';
import RefreshBtn from 'client/img/refresh.svg';
import t from 'i18n';
import store from 'client/store';
import { MIN_PASSWORD_LENGTH } from '../constants';
import PasswordWithValidate from 'components/auth/components/field-password/PasswordWithValidate';

@observer
export class Register extends React.Component {
	@observable lastName = '';
	@observable firstName = '';
	@observable middleName = '';
	@observable email = '';
	@observable password = '';
	@observable repeatedPassword = '';
	@observable captcha = '';
	@observable reloads = 0;
	@observable isLoading = false;
	@observable errors = {
		email: null,
		password: null,
		repeatedPassword: null,
		general: null,
		captcha: null,
	};
	@observable validPasswordComplexity = true;

	fields = ['email', 'password', 'repeatedPassword'];

	constructor(props) {
		super(props);
		this.time = new Date().getTime();
		this.elements = {};
	}

	resetErrors = () => {
		Object.keys(this.errors).forEach((key) => {
			this.errors[key] = null;
		});
	};

	onMount = (name) => (el) => (this.elements[name] = el);

	focus = (name) => this.elements[name] && this.elements[name].focus();

	onChange = (fieldName) => (e) => (this[fieldName] = e.target.value);

	refresh = () => this.reloads++;

	isEmailValid = () => {
		if (this.elements['email']) {
			return this.elements['email'].matches(':valid');
		}
		return false;
	};

	validatePassword = () => {
		let valid = true;
		let error;
		if (this.password.length < MIN_PASSWORD_LENGTH) {
			valid = false;
			error = t('auth.minPasswordLength') + ': ' + MIN_PASSWORD_LENGTH;
		}
		return { valid, error };
	};

	checkFieldLength = (field) => {
		const _fieldValue = this[field].trim();

		if (_fieldValue.length === 0) {
			const capitalizeFieldName = field.charAt(0).toUpperCase() + field.slice(1);
			this.errors[field] = t(`auth.enter${capitalizeFieldName}`);
			this.focus(field);
			return false;
		} else if (field === 'password') {
			const validity = this.validatePassword();

			if (!validity.valid) {
				this.errors.password = validity.error;
				this.focus('password');
				return false;
			}
			if (!this.validPasswordComplexity) {
				this.errors.password = t('auth.simplePassword');
				this.focus('password');
				return false;
			}
		} else if (field === 'email' && !this.isEmailValid()) {
			this.errors.email = t('auth.invalidEmail');
			this.focus('email');
			return false;
		}

		return true;
	};

	submit = async () => {
		this.resetErrors();

		const isFieldsValidationSuccess = this.fields.every(this.checkFieldLength);

		if (isFieldsValidationSuccess) {
			if (this.password !== this.repeatedPassword) {
				this.errors.repeatedPassword = t('auth.noMatch');
				this.focus('repeatedPassword');
				return;
			}

			this.isLoading = true;
			try {
				const user = await store.model.User.create({
					firstName: this.firstName,
					lastName: this.lastName,
					middleName: this.middleName,
					email: this.email,
					password: this.password,
					captcha: this.captcha,
				});
				await store.model.login(this.email, this.password, this.captcha);
				store.route.push({ path: '/management/tasks' });
			} catch (e) {
				this.errors.captcha = t(e.code);
				if (e.code === 'INVALID_CAPTCHA') {
					this.focus('captcha');
				}
				if (e.statusCode === 422) {
					this.errors.email = t('auth.emailExists');
					this.focus('email');
				}

				this.captcha = '';
			}

			this.reloads++;
			this.isLoading = false;
		}
	};

	back = () => store.route.push({ path: '/login' });

	render() {
		return (
			<>
				<form className={'auth-form registration' + (this.isLoading ? ' disabled' : '')}>
					<h3>{t('auth.registration')}</h3>
					<div className='field'>
						<label>{t('auth.lastName')}</label>
						<input type='text' value={this.lastName} onChange={this.onChange('lastName')} />
					</div>
					<div className='field'>
						<label>{t('auth.firstName')}</label>
						<input type='text' value={this.firstName} onChange={this.onChange('firstName')} />
					</div>
					<div className='field'>
						<label>{t('auth.middleName')}</label>
						<input type='text' value={this.middleName} onChange={this.onChange('middleName')} />
					</div>
					<div className='field'>
						<label className='required'>{t('auth.email')}</label>
						<input type='email' value={this.email} onChange={this.onChange('email')} required ref={this.onMount('email')} />
						<ErrorMessage field={this.errors.email} />
					</div>
					<div className='field'>
						<PasswordWithValidate
							isRequired
							label={t('auth.password')}
							value={this.password}
							onChange={this.onChange('password')}
							inputRef={this.onMount('password')}
							error={this.errors.password}
							autocomplete='new-password'
							onCheckInvalid={(items) => (this.validPasswordComplexity = !items.length)}
							options={store.mergedConfig?.authentication?.password}
						/>
					</div>
					<FieldPassword
						isRequired
						label={t('auth.repeatPassword')}
						value={this.repeatedPassword}
						onChange={this.onChange('repeatedPassword')}
						inputRef={this.onMount('repeatedPassword')}
						error={this.errors.repeatedPassword}
					/>
					<div className='field last'>
						<label className='required'>{t('auth.captcha')}</label>
						<input
							type='text'
							name='captcha'
							value={this.captcha}
							onChange={this.onChange('captcha')}
							ref={this.onMount('captcha')}
						/>
						<ErrorMessage field={this.errors.captcha} />
					</div>
					<div className='captcha'>
						<img src={`/api/captcha?new-${this.time}-${this.reloads}`} ref={(el) => (this.img = el)} alt='' />
						<RefreshBtn className='reload' onClick={this.refresh} />
					</div>
					<div className='submit'>
						{!this.isLoading && (
							<>
								<Button text={t('auth.register')} variant='primary' onClick={this.submit} />
								<Button text={t('auth.goBack')} variant='default' onClick={this.back} />
							</>
						)}
						{this.isLoading && <Loader size={18} style={{ marginLeft: 10 }} />}
					</div>
					<ErrorMessage field={this.errors.general} />
				</form>
			</>
		);
	}
}
