import React from 'react';
import { HistoryWidgetStore } from 'components/history-widget/store';
import { Loader } from '@smartplatform/ui';
import t from 'i18n';
import { observer } from 'mobx-react';
import './style.scss';

@observer
export class HistoryWidget extends React.Component {
	wrapperEl = null;
	constructor(props) {
		super(props);
		this.store = new HistoryWidgetStore(this.props.model, this.props.filter);
		if (props.getInstance) props.getInstance({ reload: this.store.reload });
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.filter.order !== this.props.filter.order) {
			this.store.updateFilter(this.props.filter);
			this.store.reload();
		}
	}

	onMount = (el) => {
		if (el) {
			this.wrapperEl = el;
			this.wrapperEl.addEventListener('scroll', this.store.onScroll);
		}
	};

	componentWillUnmount() {
		if (this.wrapperEl) this.wrapperEl.removeEventListener('scroll', this.store.onScroll);
	}

	render() {
		const { records, isLoading } = this.store;
		const { renderItem } = this.props;
		if (isLoading) return <Loader size={20} />;
		return (
			<div className='wrapper-history-widget' ref={this.onMount}>
				{records.length ? records.map((record, index) => renderItem(record, index)) : t('history.notFound')}
			</div>
		);
	}
}
